import { css, sroStyle } from '@amedia/brick-tokens';

// Export sroStyle style for use in other components
export { sroStyle };

export const carouselStyle = css({
  $$carouselWidth: '100%',
  $$contentWidth: '45%', // value is overwritten in brick-carousel.ts
  $$contentGap: '12px', // change value in "slideWidth" in brick-carousel.ts if this changes
  $$colorGrey: 'hsl(0, 0%, 44%)',
  $$colorWhite: 'hsl(360 100% 100% / 100%)',
  $$colorWhiteDisabled: 'hsl(360 100% 100% / 60%)',
  $$colorAlphaWhite: 'hsl(360 100% 100% / 80%)',
  $$colorBlack: 'hsl(0 0% 0% / 100%)',
  $$imgBackgrColor: 'hsl(0 0% 20% / 100%)',

  display: 'block',
  position: 'relative',
  maxWidth: '$$carouselWidth',
  overflow: 'hidden',
  '--brick-image-sizes': '(max-width: 500px) 100vw, 280px',
  '&[type="gallery"]': {
    '--brick-image-sizes': '(max-width: 500px) 100vw, 980px',
  },

  // Skip link
  'a[href="#carousel-skip-target"]': {
    fontSize: '1.2rem',
    color: '$$colorBlack',
    display: 'inline-block',
    outline: '2px solid $$colorBlack',
    outlineOffset: '-2px',
    padding: '0.2rem',
  },

  'a[href="#carousel-skip-target"]:focus-visible': {
    textDecoration: 'none',
  },

  '& .contents-wrapper': {
    display: 'grid',
    gridTemplateColumns: 'repeat(20, $$contentWidth)', // correct number set with JS
    columnGap: '$$contentGap',
    margin: '0',
    padding: '0',
    overflowY: 'hidden',
    overflowX: 'auto',
    overscrollBehaviorInline: 'contain',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '& .contents-wrapper:not(.grabbing)': {
    scrollBehavior: 'smooth',
    '@media (prefers-reduced-motion)': {
      scrollBehavior: 'auto',
    },
  },

  '&.navigation .carousel:hover, &.navigation .content-wrapper:hover, &.navigation .content-wrapper a:hover':
    {
      cursor: 'grab',
    },

  '&.navigation .grabbing:active': {
    cursor: 'grabbing',
  },

  '&.navigation .carousel:hover, &.navigation .content-wrapper:hover': {
    cursor: 'default',
  },

  '& .content-wrapper': {
    maxWidth: '100%',
    overflow: 'hidden',
    listStyle: 'none',
    scrollSnapAlign: 'start',
  },

  '& .grabbing .content-wrapper': {
    userSelect: 'none',
    scrollSnapAlign: 'none',
    pointerEvents: 'none',
  },

  // Make sure each direct child is of equal height
  '& .content-wrapper > p + *': {
    height: '100%',
  },

  // Navigation
  '& .carousel-nav': {
    position: 'absolute',
    top: '0',
    zIndex: '1',
    minWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    padding: '0',
    margin: '0',
    pointerEvents: 'none',

    '& button': {
      all: 'unset',
      appearance: 'none',
      background: 'transparent',
      width: '3.125rem',
      height: '3.125rem',
      zIndex: '1',
      cursor: 'pointer',
      pointerEvents: 'auto',
      color: '#000',
      display: 'none',

      '& > div': {
        position: 'relative',
        borderRadius: '50%',
        width: 'clamp(2rem, 6vw, 3.125rem)',
        height: 'clamp(2rem, 6vw, 3.125rem)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '$$colorAlphaWhite',
        boxShadow: '0 3px 8px hsl(0 0% 0% / 20%)',
        transition: 'background .1s ease',
      },

      '& svg': {
        width: 'clamp(1.125rem, 3vw, 2.125rem)',
        height: 'clamp(1.125rem, 3vw, 2.125rem)',
      },
    },

    '.prev-btn div': {
      left: '0.938rem',
    },

    '.next-btn div': {
      right: '0.938rem',
    },

    '@media only screen and (min-width: 37.5em)': {
      '& .prev-btn div': {
        left: '1.5rem',
      },

      '& .next-btn div': {
        right: '1.5rem',
      },
    },

    '& button:hover > div, & button:focus-visible > div': {
      background: 'hsl(243 0% 20% / 80%)',

      '& > svg': {
        fill: '$$colorWhite',
      },
    },

    '& button:focus-visible > div': {
      outline: '2px solid $$colorWhite',
      outlineOffset: '1px',
    },

    '& button:disabled': {
      cursor: 'default',
    },

    '& button:disabled > div, & button:hover:disabled > div': {
      background: 'hsl(243 0% 20% / 60%)',
      '& > svg': {
        fill: '$$colorWhiteDisabled',
      },
    },
  },
});

export const twoItemsImageSizes = css({
  '--brick-image-sizes': '(max-width: 500px) 50vw, 480px',
});
