/* eslint-disable import/extensions */
import {
  BrickElement,
  defineCustomElement,
  type EventListenerObject,
} from '@amedia/brick-template';

import { carouselStyle, sroStyle, twoItemsImageSizes } from './styles';
import { BrickCarouselData, carouselTemplate } from './template';
import {
  isTouchEnabled,
  mapToCarouselType,
  startHandler,
  moveHandler,
  dragState,
  doScroll,
} from './utils';
import { debounce } from './debounce';

@defineCustomElement({
  selector: 'brick-carousel',
})
export class BrickCarousel extends BrickElement {
  data: BrickCarouselData;
  private _sliderWidth!: number;
  private _minSlidesToShow: number;
  private _btns: NodeListOf<HTMLButtonElement> | null;
  slidesToShow: number;
  gridColWidth: string;
  numberOfSlides: number;
  isScrolling: boolean;
  slider: HTMLDivElement | null;
  section: HTMLDivElement | null;
  slideContentsArray: HTMLLIElement[] | null;
  slideWidth!: number;
  uniqueId: string;
  hideBtnStartEnd?: boolean;

  constructor(data: BrickCarouselData) {
    super();
    this.data = data;
    this.slider = null;
    this.section = null;
    this.slideContentsArray = null;
    this.sliderWidth = 768;
    this.slidesToShow = 3;
    this.minSlidesToShow = 1;
    this.gridColWidth = '30%';
    this.numberOfSlides = 0;
    this.isScrolling = false;
    this.uniqueId = this.generateUUID();
    this.hideBtnStartEnd = false;

    //Binding event handlers to make sure they can be properly removed
    this.btnClick = this.btnClick.bind(this);
    this.reEnableButton = this.reEnableButton.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.updateButtonsAfterScroll = this.updateButtonsAfterScroll.bind(this);
    this._btns = null;
    this._minSlidesToShow = 1;
  }

  async connectedCallback() {
    this.classList.add(carouselStyle());
    this.setData();

    super.connectedCallback();

    this.slideContentsArray = Array.from(this.getSlideContents());
    console.log(this.slideContentsArray.length);
    this.sliderWidth = this.clientWidth || 768;
    this.minSlidesToShow = parseInt(
      this.getAttribute('min-slides-to-show') || '1'
    );
    if (this.slideContentsArray.length === 2) {
      this.classList.add(twoItemsImageSizes);
    }
    this.initializeSlider();

    // Set unique id on certain attributes
    const skipLink = this.querySelector('a[href^="#skip-"]');
    if (skipLink) {
      skipLink.setAttribute('href', `#skip-${this.uniqueId}`);
    }

    const section = this.querySelector('section');
    if (section) {
      section.setAttribute(
        'aria-describedby',
        `carousel-title-${this.uniqueId}`
      );
    }

    const title = this.querySelector('[id^="carousel-title-"]');
    if (title) {
      title.setAttribute('id', `carousel-title-${this.uniqueId}`);
    }

    const skipTarget = this.querySelector('div[id^="skip-"]');
    if (skipTarget) {
      skipTarget.setAttribute('id', `skip-${this.uniqueId}`);
    }
  }

  setData() {
    this.data = {
      children: this.querySelectorAll('brick-carousel > *'),
      type: mapToCarouselType(this.getAttribute('type') || 'carousel'),
    };
  }

  toggleButtons() {
    if (this.btns && this.slider) {
      const scrollPosition = Math.ceil(this.slider.scrollLeft);

      // Disable previous button if we're at the start
      this.btns[0].disabled = scrollPosition <= this.slideWidth;
      // Disable next button if we're at the end
      const isAtEnd =
        scrollPosition >= this.slider.scrollWidth - this.slider.clientWidth;

      if (isAtEnd) {
        this.btns[1].disabled = true;
        this.btns[0].disabled = false;
      } else this.btns[1].disabled = false;

      // Hide next button if we're at the end and hideBtnStartEnd is true
      if (this.hasAttribute('hide-btn-start-end') && isAtEnd) {
        this.btns[1].classList.add(sroStyle);
        // Set focus to previous button if next button is hidden
        this.btns[0].focus();
      } else {
        this.btns[1].classList.remove(sroStyle);
      }
      // Hide previous button if we're at the start and hideBtnStartEnd is true
      if (
        this.hasAttribute('hide-btn-start-end') &&
        scrollPosition <= this.slideWidth
      ) {
        this.btns[0].classList.add(sroStyle);
        // Set focus to next button if previous button is hidden
        this.btns[1].focus();
      } else {
        this.btns[0].classList.remove(sroStyle);
      }
    }
  }

  calculateSlideCountInVew = (): number => {
    const { numberOfSlides, minSlidesToShow, sliderWidth } = this;
    if (this.data.type === 'gallery') {
      return 1;
    }

    if (numberOfSlides === minSlidesToShow || sliderWidth < 460) {
      return minSlidesToShow;
    } else if (sliderWidth <= 768) {
      return 2;
    } else if (sliderWidth > 768 && numberOfSlides <= 3) {
      return numberOfSlides;
    } else {
      return 3;
    }
  };

  calculateSliderGrid = (): string => {
    const { numberOfSlides, minSlidesToShow, sliderWidth } = this;
    if (this.data.type === 'gallery') {
      return '100%';
    }
    if (numberOfSlides === minSlidesToShow) {
      return '1fr';
    } else if (sliderWidth < 460) {
      return '85%';
    } else if (sliderWidth <= 768 && numberOfSlides === 2) {
      return '1fr';
    } else if (sliderWidth <= 768) {
      return '45%';
    } else if (sliderWidth > 768 && numberOfSlides <= 3) {
      return '1fr';
    } else {
      return '30%';
    }
  };

  setSlides = (slider: HTMLDivElement) => {
    slider.style.gridTemplateColumns = `repeat(${this.numberOfSlides}, ${this.gridColWidth})`;
    slider.style.scrollSnapType = 'inline mandatory';
  };

  get minSlidesToShow() {
    return this._minSlidesToShow || 1;
  }

  set minSlidesToShow(value) {
    const numberOfSlides = this.getSlideContents().length;
    if (value && numberOfSlides === value) {
      this._minSlidesToShow = value;
    } else {
      this._minSlidesToShow = 1;
    }
  }

  get sliderWidth() {
    return this._sliderWidth;
  }

  set sliderWidth(value) {
    this._sliderWidth = value;
  }

  get btns() {
    return this._btns;
  }

  set btns(value) {
    this._btns = value;
  }

  //Using the brick-element eventListeners property, to avoid having to handle set up and clean up of these event listeners in the connectedCallback and disconnectedCallback
  get eventListeners(): EventListenerObject[] {
    return [
      {
        selector: 'section',
        action: 'keydown',
        listener: this.handleKeydown.bind(this),
      },
      {
        selector: 'window',
        action: 'resize',
        listener: debounce(this.handleResize.bind(this)),
      },
      {
        selector: 'window',
        action: 'orientationchange',
        listener: debounce(this.handleResize.bind(this)),
      },
    ];
  }

  removeNavigationEvents() {
    const { slider } = this;
    if (!slider) return;

    slider.removeEventListener('mousedown', this.handleMouseDown);
    slider.removeEventListener('mouseleave', this.handleMouseLeave);
    slider.removeEventListener('mouseup', this.handleMouseLeave);
    slider.removeEventListener('mousemove', this.handleMouseMove);
  }

  addNavigationEvents() {
    if (!this.slider) return;

    // Click & Drag
    this.slider.addEventListener('mousedown', this.handleMouseDown);
    this.slider.addEventListener('mouseleave', this.handleMouseLeave);
    this.slider.addEventListener('mouseup', this.handleMouseLeave);
    this.slider.addEventListener('mousemove', this.handleMouseMove);

    // Button Clicks
    if (this.btns) {
      this.btns.forEach((btn) => btn.addEventListener('click', this.btnClick));
    }

    // Toggle buttons after scroll has stopped
    this.slider?.addEventListener('scroll', this.updateButtonsAfterScroll);
  }

  handleEvents() {
    this.addNavigationEvents();
  }

  handleMouseDown = (e: MouseEvent) => {
    startHandler(this.slider, e.clientX);
  };

  handleMouseMove = (e: MouseEvent) => {
    moveHandler(this.slider, e.clientX);
  };

  handleMouseLeave = () => {
    dragState.isDown = false;
    if (this.slider) {
      this.slider.classList.remove('grabbing');
      this.slider.style.scrollSnapType = 'none';
      this.toggleButtons();
    }
  };

  updateButtonsAfterScroll() {
    if (!this.isScrolling) {
      this.isScrolling = true;
      requestAnimationFrame(() => {
        this.toggleButtons();
        this.isScrolling = false;
      });
    }
  }

  skipTarget() {
    return this.querySelector('div[data-target="skip"') as HTMLAnchorElement;
  }

  generateUUID() {
    if (typeof crypto === 'object') {
      if (typeof crypto.randomUUID === 'function') {
        // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
        return crypto.randomUUID();
      }
      if (
        typeof crypto.getRandomValues === 'function' &&
        typeof Uint8Array === 'function'
      ) {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        const callback = (c) => {
          const num = Number(c);
          return (
            num ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
          ).toString(16);
        };
        return '10000000-1000-4000-8000-100000000000'.replace(
          /[018]/g,
          callback
        );
      }
    }
    let timestamp = new Date().getTime();
    let perforNow =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let random = Math.random() * 16;
      if (timestamp > 0) {
        random = (timestamp + random) % 16 | 0;
        timestamp = Math.floor(timestamp / 16);
      } else {
        random = (perforNow + random) % 16 | 0;
        perforNow = Math.floor(perforNow / 16);
      }
      return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
    });
  }

  handleResize = () => {
    this.sliderWidth = this.clientWidth;

    if (!this.slider) return;
    const slideCountInVew = this.calculateSlideCountInVew();
    const gridColumn = this.calculateSliderGrid();

    if (
      this.data.type !== 'gallery' &&
      this.slidesToShow === slideCountInVew &&
      this.gridColWidth === gridColumn
    ) {
      return;
    } else {
      this.slidesToShow = slideCountInVew;
      this.gridColWidth = gridColumn;
    }

    this.setSlides(this.slider);
  };

  reEnableButton() {
    if (!this.btns) return;
    /* 
      Find the button that was clicked and disabled. 
      Then enable it again, and give it focus (for keyboard users) 
      */
    const btnsArray = Array.from(this.btns);
    const disabledButton = btnsArray.find(
      (btn) => btn.disabled
    ) as HTMLButtonElement;

    if (disabledButton == null) return; // is undefined if event was drag instead of click
  }

  btnClick = (event: MouseEvent) => {
    const buttonClicked = (event.target as Element).closest(
      'button'
    ) as HTMLButtonElement;
    if (this.slider) {
      this.slider.style.scrollSnapType = 'inline mandatory';
    }
    if (buttonClicked.classList.contains('prev-btn')) {
      // If btns[0] is clicked, then scroll back
      doScroll(this.slider, -this.slideWidth);
    } else if (buttonClicked.classList.contains('next-btn')) {
      // If btns[1] is clicked, then scroll forward
      doScroll(this.slider, this.slideWidth);
    }
  };

  getSlider = () =>
    this.querySelector('[data-contents-wrapper]') as HTMLDivElement;

  getSection = () => this.querySelector('.carousel') as HTMLDivElement;

  getSlideContents = () =>
    this.querySelectorAll(
      '[data-content-wrapper]'
    ) as NodeListOf<HTMLLIElement>;

  initializeSlider = () => {
    this.slider = this.getSlider();
    if (!this.slider) return;
    const slideContents = this.getSlideContents();
    // If no content is found, return
    if (!slideContents) return;
    this.numberOfSlides = slideContents.length;
    this.section = this.getSection();

    this.btns = this.querySelectorAll(
      '[data-content-slider-btn]'
    ) as NodeListOf<HTMLButtonElement>;

    // Move all the content into the slider
    this.data.children.forEach((content, index: number) => {
      slideContents[index].appendChild(content);
    });

    // Show either 1, 2 or 3 slides at a time depending on carousel width
    // this.calculateSlides(this.slider);
    this.slidesToShow = this.calculateSlideCountInVew();
    this.gridColWidth = this.calculateSliderGrid();

    this.setSlides(this.slider);

    // Get the width of the first slide so we can use it to set scroll amount when using keyboard navigation or buttons
    this.slideWidth = this.getSlideContents()[0].clientWidth;

    this.handleEvents();

    // Add nav buttons if there are more slides than can fit in the carousel view and touch is not enabled
    // or if the carousel type is gallery
    if (
      (!isTouchEnabled && this.numberOfSlides > this.slidesToShow) ||
      this.data.type === 'gallery'
    ) {
      this.btns.forEach((btn) => (btn.style.display = 'block'));
      this.classList.add('navigation');
    }
    this.setAttribute('initialized', 'true');
  };

  async disconnectedCallback() {
    super.disconnectedCallback();
    this.removeNavigationEvents();
  }

  static get observedAttributes() {
    return ['hide-btn-start-end', 'initialized'];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name && oldValue === newValue) return;

    if (name === 'hide-btn-start-end') {
      if (this.hasAttribute('initialized')) {
        this.toggleButtons();
      }
    }
    if (name === 'initialized') {
      if (this.hasAttribute('hide-btn-start-end')) {
        this.toggleButtons();
      }
    }
  }

  get HTML() {
    return carouselTemplate(this.data);
  }

  handleKeydown(event: KeyboardEvent) {
    const firstFocusable = this.querySelectorAll(
      '[data-content-wrapper]:nth-last-of-type(2) button, [data-content-wrapper]:nth-last-of-type(2) [href], [data-content-wrapper]:nth-last-of-type(2) input, [data-content-wrapper]:nth-last-of-type(2) [tabindex="0"]'
    )[0] as HTMLElement | null;

    switch (event.key) {
      // If left arrow key is pressed, move to previous slide
      case 'ArrowLeft':
        doScroll(this.slider, -this.slideWidth);
        break;
      // If right arrow key is pressed, move to next slide
      case 'ArrowRight':
        doScroll(this.slider, this.slideWidth);
        break;
      case 'Tab':
        // If user has tabbed to the last data-content-wrapper, hide the next button
        if (!this.btns) return;
        this.btns[1]?.classList.add('hidden');
        if (firstFocusable === document.activeElement) {
          this.btns[1].disabled = true;
        }
        break;
      // Escape to skip out of the carousel
      case 'Escape':
        this.skipTarget().focus();
        break;
      default:
        return;
    }
  }
}
