import type { CarouselType } from './template';

export const isTouchEnabled =
  'ontouchstart' in window || navigator.maxTouchPoints > 0;

const carouselTypes: CarouselType[] = ['carousel', 'gallery'];

export const mapToCarouselType = (carouselType: string): CarouselType => {
  const isValid = carouselTypes.some((type) => type === carouselType);
  return isValid ? (carouselType as CarouselType) : 'carousel';
};

export const dragState = {
  isDown: false,
  isDragging: false,
  startX: 0,
  scrollLeft: 0,
};

export const startHandler = (slider, clientX: number) => {
  dragState.isDown = true;
  dragState.isDragging = false;
  dragState.startX = clientX;
  dragState.scrollLeft = slider.scrollLeft;
};

export const moveHandler = (slider, clientX: number) => {
  if (!dragState.isDown) return;
  if (dragState.isDown) {
    slider.classList.add('grabbing');
    const x = clientX;
    const walk = x - dragState.startX;
    slider.scrollLeft = dragState.scrollLeft - walk;
    if (Math.abs(walk) > 5) {
      // 5px threshold, can be adjusted
      dragState.isDragging = true;
    }
  }
};

export const doScroll = (slider, scrollLength) => {
  slider.classList.remove('grabbing');

  slider.scrollBy({
    top: 0,
    left: scrollLength,
  });
};
